import { iNewsData } from './news.service';

export const NewsListEn: iNewsData[] = [
  {
    id: 0,
    title: 'NATO COMMERCIAL AND GOVERNMENTAL ENTITY (NCAGE) CODE',
    content: 'We are pleased to announce that in July 2013 „S.I.A.P” Sp. z o. o. was assigned the NATO Commercial and Government Entity (NCAGE) Code.',
    date: '19.03.2013',
    isPromoted: true
  },
];
