<div ngbDropdown class="d-inline-block languages test">
  <button class="btn btn-outline-primary languages__indicator" id="dropdownBasic1" ngbDropdownToggle>
    <img src="{{this.iconUrl}}" alt="{{this.lang}}"/>
    <i class="icon-angle-down"></i>
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="languages__list">
    <button class="dropdown-item language" (click)="setLanguage('pl')">
      <img src="assets/img/languages_pl.jpg" alt="{{'CORE.LANG.lang-pl' | translate}}"/>
    </button>
    <button class="dropdown-item language" (click)="setLanguage('en')">
      <img src="assets/img/languages_en.jpg" alt="{{'CORE.LANG.lang-en' | translate}}"/>
    </button>
  </div>
</div>


