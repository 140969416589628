import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidationService } from '../../../core/services/validation.service';
import { OrderService } from '../order.service';
import { ScrollToElementService } from '../../../core/services/scroll-to-element.service';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: [ './order-form.component.scss' ]
})
export class OrderFormComponent implements OnInit {
  @ViewChild('form', {static: true}) form: ElementRef;
  isLoading: boolean;
  isSended: boolean;
  isError: boolean;


  get formProducts() {
    return <FormArray>this.orderForm.get('products');
  }

  orderForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
    private scrollService: ScrollToElementService,
    private orderService: OrderService) {
  }

  ngOnInit() {
    this.isSended = false;
    this.isError = false;
    this.isLoading = false;
    this.orderForm = this.formBuilder.group({
      orderType: [ '0', [ Validators.required ] ],
      products: this.formBuilder.array([]),
      client: this.formBuilder.group({
        fullName: [ '', [ Validators.required ] ],
        company: [ '', [ Validators.required ] ],
        email: [ '', [ ValidationService.emailValidator, Validators.required ] ],
        nip: [ '', [ValidationService.onlyNumbers] ],
        phoneNumber: [ '', [ Validators.required, ValidationService.onlyNumbers ] ],
        contactMethod: [ 'email', [ Validators.required ] ]
      })
    });

    this.addProduct();
  }

  initProduct() {
    return this.formBuilder.group({
      typeName: [ '', [] ],
      voltage: [ '', [] ],
      capacity: [ '', [] ],
      outerDimension: [ '', [] ],
      innerDimension: [ '', [] ],
      connectionWay: [ '', [] ],
      aquaSystem: [ 'not-selected', [] ],
      orderQuantity: [ '', [] ],
    });
  }

  addProduct(): void {
    const products = <FormArray>this.orderForm.controls[ 'products' ];
    products.push(this.initProduct());
  }

  removeProduct(i: number) {
    const products = <FormArray>this.orderForm.controls[ 'products' ];

    products.removeAt(i);
  }

  submitOrder() {
    if (this.orderForm.valid) {
      this.isLoading = true;
      this.orderService
          .sendOrder(this.orderForm.value)
          .subscribe(
            (res) => {
              this.isLoading = false;
              this.isSended = true;
              this.isError = false;
            },
            (err) => {
              this.isLoading = false;
              this.isError = true;
              this.isSended = false;
            }
          );
    } else {
      this.markFormGroupTouched(this.orderForm.controls[ 'client' ]);
      const firstInvalidInput = this.form.nativeElement.querySelector('input.ng-invalid');
      // todo think about validation for product
      // this.orderForm.controls.products['controls'].forEach(product => this.markFormGroupTouched(product));
      this.scrollService.scroll(firstInvalidInput);
    }
  }

  // TODO move to shared utils, duplicated
  private markFormGroupTouched(formGroup: any) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

}
