import { iNewsData } from './news.service';

export const NewsList: iNewsData[] = [
  {
    id: 0,
    title: 'Natowski Kod Podmiotu Gospodarki Narodowej NCAGE',
    content: 'Mamy przyjemność poinformować, że w lipcu 2013 roku został przydzielony Spółce „S.I.A.P” Sp. z o.o - Natowski Kod Podmiotu Gospodarki Narodowej NCAGE NATO Commercial and Goverment Entity Code) o nr 2447HJest to autoryzacja na oferowanie usług i towarów dla krajów członkowskich NATO, a przyznawana przez Wojskowe Centrum Normalizacji, Jakości i Kodyfikacji. Tym samym Spółka „S.I.A.P” Sp. z o.o znalazła się w Bazie Podmiotów Gospodarki Narodowej (NCS) oraz dane Spółki zostaną wprowadzone do Natowskiej Bazy Podmiotów Gospodarki Narodowej NMCRL (NATO Master Catalogue og References for Logistics). Uzyskany certyfikat NCAGE umożliwia uczestnictwo w ograniczonych przetargach przeznaczonych dla oferentów usług i towarów dla struktur NATO oraz dla nabywców wyrobów na rynku cywilnym. Natowski System Kodyfikacyjny (NCS) jest jednolitym i wspólnym systemem krajów NATO identyfikacji, klasyfikacji i zarządzania pozycjami zaopatrzenia. Jest przeznaczony do osiągania maksimum efektywności i wsparcia logistycznego. System ten został wdrożony w krajach członkowskich NATO.Przydzielony kod jest wyróżnikiem Spółki „S.I.A.P” Sp. z o.o z siedzibą w Gliwicach w Natowskim Systemie Kodyfikacyjnym NCS (NATO Codification System) oraz dodatkowym potwierdzeniem wysokiej jakości naszych wyrobów, które możemy zaoferować potencjalnym klientom logistyki wojskowej.',
    date: '19.03.2013',
    isPromoted: true
  },
];
