<div class="news-list">
  <swiper class="news-list__slider swiper js-newsSlider" [config]="config">
    <div class="swiper-wrapper">
      <div class="swiper-slide" *ngFor="let news of newsList">
        <app-news [newsDate]="news"></app-news>
      </div>
    </div>
  </swiper>

  <div class="swiper-pagination news-list__pagination js-newsSliderPagination"></div>
  <div class="swiper-button-next news-list__next js-newsSliderNext"><i class="icon-angle-right"></i></div>
  <div class="swiper-button-prev news-list__prev js-newsSliderPrev"><i class="icon-angle-left"></i></div>
</div>
