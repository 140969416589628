<div class="content-wrapper_margin">
  <div class="products-type">
    <div class="container">
      <h1 class="page-title">{{'CORE.MENU.products' | translate}}</h1>

      <div class="products-tabs">
        <div class="products-tabs__header {{choosenProductType}}">
          <div class="products-tabs__tab products-tabs__tab_blue" 
            (click)="changeProductType('blue')"
            innerHTML="{{'CORE.PRODUCT-TYPE.name-blue' | translate}}">
          </div>
          <div class="products-tabs__tab products-tabs__tab_pink" 
            (click)="changeProductType('pink')"
            innerHTML="{{'CORE.PRODUCT-TYPE.name-pink' | translate}}">
          </div>
          <div class="products-tabs__tab products-tabs__tab_yellow" 
            (click)="changeProductType('yellow')"
            innerHTML="{{'CORE.PRODUCT-TYPE.name-yellow' | translate}}">
          </div>
          <div class="products-tabs__tab products-tabs__tab_green" 
            (click)="changeProductType('green')"
            innerHTML="{{'CORE.PRODUCT-TYPE.name-green' | translate}}">
          </div>
        </div>
        <div class="products-tabs__descriptions">
          <app-product-type-description [typeColor]="this.choosenProductType"></app-product-type-description>
        </div>
      </div>
    </div>
  </div>
</div>
