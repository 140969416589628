<div class="content-wrapper_margin">
  <div class="contact">
    <div class="container">
      <div class="contact-top">
        <h1 class="page-title">{{'CORE.MENU.contact' | translate}}</h1>

        <div class="row">
          <div class="col-lg-3 col-md-4  contact-info_bordered">
            <div class="contact__title">{{'CORE.CONTACT.company' | translate}}</div>
            <div class="contact__adress" [innerHTML]="'CORE.CONTACT.adress' | translate"></div>
          </div>
          <div class="col-lg-4 col-md-4 contact-info_bordered">
            <div class="contact__info contact-numbers">
              <div class="contact__row">
                <div class="contact__row">
                  <strong>{{'CORE.CONTACT.vat' | translate}}:</strong>
                  <span>6311224779</span>
                </div>

                <div class="contact__row">
                  <strong>{{'CORE.CONTACT.regon' | translate}}:</strong>
                  <span>273119927</span>
                </div>

                <div class="contact__row">
                  <strong>{{'CORE.CONTACT.krs' | translate}}:</strong>
                  <span>0000016968</span>
                </div>

                <div class="contact__row">
                  <strong>{{'CORE.CONTACT.bdo' | translate}}:</strong>
                  <span>000002897</span>
                </div>

                <div class="contact__row">
                  <strong>{{'CORE.CONTACT.eori' | translate}}:</strong>
                  <span>631122477900000</span>
                </div>

              </div>

            </div>

          </div>
          <div class="col-lg-5 col-md-4 contact-info_bordered">
            <div class="contact__bank-info">

              <span class="contact__bank-title">Bank PEKAO S.A</span>
              <div class="contact__row">
                <label>PLN:</label>
                <strong>90 1240 1343 1111 0000 2360 0076</strong>
              </div>
              <div class="contact__row contact__row_smaller">
                <label>Euro:</label>
                <strong>PL56 1240 1343 1978 0010 0167 6238</strong>
              </div>
              <div class="contact__row">
                <label>SWIFT:</label>
                <strong>PKOPPLPW</strong>
              </div>

              <span class="contact-capital">{{'CORE.CONTACT.capital' | translate}}<br>
                {{'CORE.CONTACT.court' | translate}}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="contact-content">
        <div class="row">
          <div class="col-xl-3 col-lg-4 col-md-6" *ngFor='let contact of contactList'>
            <app-contact-box [boxData]=contact></app-contact-box>
          </div>
        </div>
      </div>

      <div class="contact-bottom">
        <div class="row">
          <div class="col-xl-8 col-lg-7">
            <div class="contact__map">
              <agm-map [latitude]="latitude" [longitude]="longitude" [scrollwheel]="false" [zoom]="zoom"
                [zoomControl]="true" [draggable]="true">
                <agm-marker [latitude]="latitude" [longitude]="longitude"
                  [iconUrl]="'./assets/img/map-marker.png'"></agm-marker>
              </agm-map>
            </div>
          </div>
          <div class="col-xl-4 col-lg-5">
            <div class="contact__info transport-info">
              <div class="transport-info__icon">
                <img src="./assets/img/transport-info.png" alt="{{'CORE.CONTACT.transport-title' | translate}}">
              </div>
              <div class="transport-info__desc">
                <strong>{{'CORE.CONTACT.transport-title' | translate}}</strong>
                <span innerHTML="{{'CORE.CONTACT.transport-hours' | translate}}"></span>
              </div>
            </div>
            <div class="contact__hour">
              <strong>{{'CORE.CONTACT.hour-title' | translate}}</strong>
              {{'CORE.CONTACT.hour' | translate}}
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
