import { Component, Input, ViewChild, OnInit, AfterContentInit, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ScrollToElementService } from '../../../../core/services/scroll-to-element.service';

@Component({
  selector: 'app-product-form',
  templateUrl: './product-form.component.html',
  styleUrls: [ './product-form.component.scss' ]
})
export class ProductFormComponent implements AfterContentInit {
  @Input() productForm: FormGroup;
  @Input() productId: number;
  @ViewChild('newProduct', {static: true}) newProduct: ElementRef;


  constructor(private scrollService: ScrollToElementService) {
  }

  ngAfterContentInit() {
    this.scrollService.scroll(this.newProduct.nativeElement);
  }
}
