import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'setDocumentLink'
})
export class SetDocumentLinkPipe implements PipeTransform {
  links = {
    pl: {
      ncage: './assets/certifications/NCAGE.pdf',
      declarationEnv: './assets/certifications/deklaracja.pdf',
      iso9001: './assets/certifications/ISO-9001.pdf',
      iso14001: './assets/certifications/ISO-14001.pdf',
      reach: './assets/certifications/reach.pdf',
      ecoLeaflet: './assets/certifications/Ulotka pro-ekologiczna.pdf',
      security: './assets/certifications/klauzula-informacyjna.pdf',
      cookies: './assets/certifications/cookies-policy.pdf',
      siap: './assets/certifications/SIAP.pdf',
      packaging: './assets/certifications/opakowania-zwrotne.pdf',
    },
    en: {
      ncage: './assets/certifications/NCAGE.pdf',
      declarationEnv: './assets/certifications/deklaracja-en.pdf',
      iso9001: './assets/certifications/ISO-9001-en.pdf',
      iso14001: './assets/certifications/ISO-14001-en.pdf',
      reach: './assets/certifications/reach-en.pdf',
      ecoLeaflet: './assets/certifications/Ulotka pro-ekologiczna-en.pdf',
      security: './assets/certifications/klauzula-informacyjna.pdf',
      cookies: './assets/certifications/cookies-policy-en.pdf',
      siap: './assets/certifications/SIAP.pdf',
      packaging: './assets/certifications/opakowania-zwrotne.pdf',
    }
  };
  
  transform(type: string, lang: string): string {
    return this.links[lang][type];
  }

}
