import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: [ './languages.component.scss' ]
})
export class LanguagesComponent implements OnInit {
  lang: string;
  iconUrl: string;

  constructor(private translate: TranslateService) {
  }

  ngOnInit() {
    if (localStorage.getItem('lang')) {
      this.setLanguage(localStorage.getItem('lang'))
    } else {
      this.lang = this.translate.currentLang;
      this.iconUrl = this.setIconUrl(this.translate.currentLang);
    }
  }

  setLanguage(lang: lang) {
    this.translate.use(lang);
    this.lang = lang;
    this.iconUrl = this.setIconUrl(lang);
    localStorage.setItem('lang', lang);
  }

  setIconUrl(lang: string) {
    return `assets/img/languages_${lang}.jpg`;
  }

}

type lang = 'pl' | 'en' | 'it' | string;
