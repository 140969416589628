import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-products-icons-list',
  templateUrl: './products-icons-list.component.html',
  styleUrls: ['./products-icons-list.component.scss']
})
export class ProductsIconsListComponent implements OnInit {

  productsCategoryList: iProductCategoryData[];

  constructor(
  ) {
  }

  ngOnInit() {
    this.productsCategoryList = productsCategoryList;
  }

}

interface iProductCategoryData {
  id: number;
  pictureUrl: string;
  color: string;
  productUrl: string;
}

const productsCategoryList: iProductCategoryData[] = [
  {
    id: 1,
    pictureUrl: './assets/img/prod-cat_blue.jpg',
    color: 'blue',
    productUrl: './assets/img/products/list-1.pdf',
  },
  {
    id: 2,
    pictureUrl: './assets/img/prod-cat_pink.jpg',
    color: 'pink',
    productUrl: './assets/img/products/list-2.pdf',

  },
  {
    id: 3,
    pictureUrl: './assets/img/prod-cat_yellow.jpg',
    color: 'yellow',
    productUrl: './assets/img/products/list-3.pdf',

  },
  {
    id: 4,
    pictureUrl: './assets/img/prod-cat_green.jpg',
    color: 'green',
    productUrl: './assets/img/products/list-4.pdf',
  }
];


