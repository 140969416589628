import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationService } from './services/validation.service';
import { OrderService} from '../main/order/order.service';
import { ComplaintService } from '../main/complaint-form/complaint.service';
import { SetDocumentLinkPipe } from './pipes/set-document-link.pipe';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    SetDocumentLinkPipe
  ],
  exports: [
    SetDocumentLinkPipe,
  ],
  providers: [
    ValidationService,
    OrderService,
    ComplaintService,
  ]
})
export class CoreModule {
}
