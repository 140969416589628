<div class="content-wrapper_margin">
  <div class="complaint-form">
    <div class="container">
      <h1 class="main-title">
        {{'COMPLAINT.title' | translate}}
      </h1>
      <form [formGroup]="complaintForm" (submit)="submitComplaint()" class="validated-form" #form>
        <div class="row row_120">
          <div class="col-md-6" formGroupName="applicant">
            <h3 class="page-subtitle">{{"COMPLAINT.applicantLabel" | translate}}</h3>

            <div class="form-group">
              <div class="label" for="firstName">
                {{"COMPLAINT.firstName" | translate}}
              </div>
              <input id="firstName" class="form-control" formControlName="firstName">
              <control-messages [control]="complaintForm.get('applicant.firstName')"></control-messages>
            </div>

            <div class="form-group">
              <div class="label" for="lastName">
                  {{"COMPLAINT.lastName" | translate}}
              </div>
                <input id="lastName" class="form-control" formControlName="lastName">
                <control-messages [control]="complaintForm.get('applicant.lastName')"></control-messages>
            </div>

            <div class="form-group">
              <div class="label" for="company">
                {{"COMPLAINT.company" | translate}}
              </div>
              <input id="company" class="form-control" formControlName="company">
              <control-messages [control]="complaintForm.get('applicant.company')"></control-messages>
            </div>

            <div class="form-group">
              <div class="label" for="address">
                {{"COMPLAINT.address" | translate}}
              </div>
              <input id="address" class="form-control" formControlName="address">
              <control-messages [control]="complaintForm.get('applicant.address')"></control-messages>
            </div>

            <div class="form-group">
              <div class="label" for="city">
                {{"COMPLAINT.city" | translate}}
              </div>
              <input id="city" class="form-control" formControlName="city">
              <control-messages [control]="complaintForm.get('applicant.city')"></control-messages>
            </div>

            <div class="form-group">
                <div class="label" for="cityCode">
                  {{"COMPLAINT.cityCode" | translate}}
                </div>
                <input id="cityCode" class="form-control" formControlName="cityCode">
                <control-messages [control]="complaintForm.get('applicant.cityCode')"></control-messages>
            </div>
          </div>
          <div class="col-md-6" formGroupName="agent">
            <h3 class="page-subtitle">
              {{"COMPLAINT.agentLabel" | translate}}
            </h3>

            <div class="form-group">
                <div class="label" for="firstName">
                  {{"COMPLAINT.firstName" | translate}}
                </div>
                <input id="firstName" class="form-control" formControlName="firstName">
                <control-messages [control]="complaintForm.get('agent.firstName')"></control-messages>
            </div>

            <div class="form-group">
                <div class="label" for="lastName">
                  {{"COMPLAINT.lastName" | translate}}
                </div>
                <input id="lastName" class="form-control" formControlName="lastName">
                <control-messages [control]="complaintForm.get('agent.lastName')"></control-messages>
            </div>

            <div class="form-group">
                <div class="label" for="phoneNumber">
                  {{"COMPLAINT.phoneNumber" | translate}}
                </div>
                <input id="phoneNumber" class="form-control" formControlName="phoneNumber">
                <control-messages [control]="complaintForm.get('agent.phoneNumber')"></control-messages>
            </div>

            <div class="form-group">
                <div class="label" for="email">
                  {{"ORDER.email" | translate}}
                </div>
                <input id="email" class="form-control" formControlName="email">
                <control-messages [control]="complaintForm.get('agent.email')"></control-messages>
            </div>
          </div>
        </div>
        <div class="row row_120" formGroupName="product">
          <div class="col-sm-12">
            <h3 class="page-subtitle">{{"COMPLAINT.productLabel" | translate}}</h3>
          </div>
          <div class="col-md-6">
              <div class="form-group">
                <div class="label" for="type">
                    {{"COMPLAINT.type" | translate}}
                </div>
                <select id="type" class="form-control" formControlName="type">
                  <option selected value="0">{{"COMPLAINT.TYPEGROUP.accumulator" | translate}}</option>
                  <option selected value="1">{{"COMPLAINT.TYPEGROUP.battery" | translate}}</option>
                  <option selected value="2">{{"COMPLAINT.TYPEGROUP.link" | translate}}</option>
                  <option selected value="3">{{"COMPLAINT.TYPEGROUP.rectifier" | translate}}</option>
                </select>
                <!-- <input id="lastName" class="form-control" formControlName="type"> -->
                <control-messages [control]="complaintForm.get('product.type')"></control-messages>
              </div>
              <div class="form-group">
                <div class="label" for="serial">
                    {{"COMPLAINT.serial" | translate}}
                </div>
                <input id="serial" class="form-control" formControlName="serial">
                <control-messages [control]="complaintForm.get('product.serial')"></control-messages>
              </div>
              <div class="form-group">
                <div class="label" for="orderQuantity">
                    {{"COMPLAINT.orderQuantity" | translate}}
                </div>
                <input id="orderQuantity" class="form-control" formControlName="orderQuantity">
                <control-messages [control]="complaintForm.get('product.orderQuantity')"></control-messages>
              </div>
              <div class="form-group">
                <div class="label" for="acquisitionDate">
                    {{"COMPLAINT.acquisitionDate" | translate}}
                </div>
                <input id="acquisitionDate" class="form-control" formControlName="acquisitionDate">
                <control-messages [control]="complaintForm.get('product.acquisitionDate')"></control-messages>
              </div>
              <div class="form-group">
                <div class="label" for="description">
                    {{"COMPLAINT.description" | translate}}
                </div>
                <textarea rows="4" id="description" class="form-control" formControlName="description"></textarea>
                <control-messages [control]="complaintForm.get('product.description')"></control-messages>
              </div>

          </div>
          <div class="col-md-6">
            <div class="form-group">
              <div class="label" for="invoice">
                  {{"COMPLAINT.invoice" | translate}}
              </div>
              <input id="invoice" class="form-control" formControlName="invoice">
              <control-messages [control]="complaintForm.get('product.invoice')"></control-messages>
            </div>
            <div class="form-group">
              <div class="label" for="invoiceDate">
                  {{"COMPLAINT.invoiceDate" | translate}}
              </div>
              <input id="invoiceDate" class="form-control" formControlName="invoiceDate">
              <control-messages [control]="complaintForm.get('product.invoiceDate')"></control-messages>
            </div>
            <div class="form-group">
              <div class="label" for="seller">
                  {{"COMPLAINT.seller" | translate}}
              </div>
              <input id="seller" class="form-control" formControlName="seller">
              <control-messages [control]="complaintForm.get('product.seller')"></control-messages>
            </div>
            <div class="form-group">
              <div class="label" for="transport">
                  {{"COMPLAINT.transport" | translate}}
              </div>
              <select id="transport" class="form-control" formControlName="transport">
                <option selected value="0">{{"COMPLAINT.TRANSPORTGROUP.own" | translate}}</option>
                <option selected value="1">{{"COMPLAINT.TRANSPORTGROUP.buyer" | translate}}</option>
                <option selected value="2">{{"COMPLAINT.TRANSPORTGROUP.seller" | translate}}</option>
              </select>
              <control-messages [control]="complaintForm.get('product.transport')"></control-messages>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-sm-right" *ngIf="!isSended">
            <button class="button form__submit" *ngIf="!isLoading">
              {{'COMPLAINT.complaintSubmit' | translate}}
            </button>
            <button class="button form__submit" *ngIf="isLoading">
              <app-spinner></app-spinner>
            </button>
          </div>
          <div class="col-sm-12 col-md-6 ml-md-auto" *ngIf="!isLoading && isSended">
            <p class="form__alert">
              <ngb-alert [type]="'success'" [dismissible]="false" >
                {{'ORDER.send_success' | translate}}
              </ngb-alert>
            </p>
          </div>
          <div class="col-sm-12 col-md-6 ml-md-auto" *ngIf="!isLoading && isError">
            <p class="form__alert">
              <ngb-alert [type]="'danger'" [dismissible]="false" >
                {{'ORDER.send_failure' | translate}}
              </ngb-alert>
            </p>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
