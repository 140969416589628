<div class="content-wrapper_margin">
  <div class="container">
    <div class="error-page">
      <img src="./assets/img/temporary.svg" alt="{{'CORE.TEMPORARY.title' | translate}}">
      <h1>{{'CORE.TEMPORARY.title' | translate}}</h1>
      <p>{{'CORE.TEMPORARY.msg-see' | translate}}
        <a href="https://drive.google.com/drive/folders/0B5bgULaC8P2rc2UyQXhvMDViYm8?usp=sharing" target="_blank">{{'CORE.TEMPORARY.projects'
        | translate}}</a>
        {{'CORE.TEMPORARY.msg-back' | translate}}<a href="/">{{'CORE.TEMPORARY.mp' | translate}}</a></p>
    </div>

  </div>

</div>
