<div class="type-desc {{this.typeColor}}">
  <div class="row">
    <div class="col-lg-9">
      <div class="type-desc__description">
        <p innerHTML="{{this.typeDescriptionsList[this.typeColor].description | translate}}"></p>
        <p innerHTML="{{this.typeDescriptionsList[this.typeColor].use | translate}}"></p>

        <div class="type-desc__actions">
          <a href="{{this.typeDescriptionsList[this.typeColor].listUrl}}" target="_blank" class="type-desc__button">
            {{'CORE.PRODUCT-TYPE.button' | translate}}
          </a>
        </div>
      </div>
    </div>

    <div class="col-lg-3">
      <div class="type-desc__pictures">
        <div class="type-desc__picture" *ngIf="this.typeDescriptionsList[this.typeColor].imgUrl1">
          <img src="{{this.typeDescriptionsList[this.typeColor].imgUrl1}}"
               alt="{{this.typeDescriptionsList[this.typeColor].name}}">
        </div>
        <div class="type-desc__picture" *ngIf="this.typeDescriptionsList[this.typeColor].imgUrl2">
          <img src="{{this.typeDescriptionsList[this.typeColor].imgUrl2}}"
               alt="{{this.typeDescriptionsList[this.typeColor].name}}">
        </div>
      </div>
    </div>
  </div>
</div>
