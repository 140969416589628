import { Component, ViewChild, OnInit, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ComplaintService } from './complaint.service';
import { ValidationService } from '../../core/services/validation.service';
import { ScrollToElementService } from '../../core/services/scroll-to-element.service';

@Component({
  selector: 'app-complaint-form',
  templateUrl: './complaint-form.component.html',
  styleUrls: ['./complaint-form.component.scss']
})
export class ComplaintFormComponent implements OnInit {
  @ViewChild('form', {static: true}) form: ElementRef;

  isLoading: boolean;
  complaintForm: FormGroup;
  isError: boolean;
  isSended: boolean;

  get formData() {
    const formData = [];
    formData.push(<FormArray>this.complaintForm.get('agent'));
    formData.push(<FormArray>this.complaintForm.get('applicant'));
    // TODO change logic when there will be more products
    formData.push(<FormArray>this.complaintForm.get('product'));
    return formData;
  }

  constructor(
    private formBuilder: FormBuilder,
    private complaintService: ComplaintService,
    private scrollService: ScrollToElementService,
  ) {}

  ngOnInit() {
    this.isError = false;
    this.isLoading = false;
    this.isSended = false;
    this.complaintForm = this.formBuilder.group({
      applicant: this.formBuilder.group({
        firstName: [null, [Validators.required]],
        lastName: [null, [Validators.required]],
        company: [null, [Validators.required]],
        address: [null, [Validators.required]],
        city: [null, [Validators.required]],
        cityCode: [null, [Validators.required]]
      }),
      agent: this.formBuilder.group({
        firstName: [null, [Validators.required]],
        lastName: [null, Validators.required],
        phoneNumber: [null, [Validators.required]],
        email: [null, [Validators.required, ValidationService.emailValidator]]
      }),
      product: this.formBuilder.group({
        type: [0, [Validators.required]],
        serial: [null, [Validators.required]],
        orderQuantity: [null, [Validators.required]],
        acquisitionDate: [null, [Validators.required]],
        invoice: [null, [Validators.required]],
        invoiceDate: [null, [Validators.required]],
        seller: [null, [Validators.required]],
        transport: [null, [Validators.required]],
        description: [null, [Validators.required]]
      })
    });
  }

  submitComplaint() {
    if (this.complaintForm.valid) {
      this.isLoading = true;
      this.complaintService
        .sendComplaint(this.complaintForm.value)
        .subscribe(
          (res) => {
            this.isLoading = false;
            this.isError = false;
            this.isSended = true;
          },
          (err) => {
            this.isLoading = false;
            this.isSended = false;
            this.isError = true;
          }
        );
    } else {
      this.formData.forEach(element => {
        this.markFormGroupTouched(element);
      });
      this.isError = true;
      this.isLoading = false;
      this.isSended = false;
      const firstInvalidInput = this.form.nativeElement.querySelector('input.ng-invalid');
      this.scrollService.scroll(firstInvalidInput);
    }
  }

  // TODO move to shared utils, duplicated
  private markFormGroupTouched(formGroup: any) {
    (<any>Object).values(formGroup.controls).forEach(control => {
      control.markAsTouched();

      if (control.controls) {
        control.controls.forEach(c => this.markFormGroupTouched(c));
      }
    });
  }

}

enum productTypeEnum {
  'akumulator',
  'bateria',
  'ogniwo',
  'prostownik'
}

