<div class="nav">
  <div class="nav__indicator hidden visible_md">
    <div
      class="nav__icon {{ isMenuOpen ? 'open' : '' }}"
      (click)="menuToggle()"
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  </div>
  <ul class="nav__list {{ isMenuOpen ? 'open' : '' }}">
    <li>
      <a routerLink="/o-firmie" routerLinkActive="active">{{
        "CORE.MENU.about" | translate
      }}</a>
    </li>
    <li>
      <a routerLink="/produkty" routerLinkActive="active">{{
        "CORE.MENU.products" | translate
      }}</a>
    </li>
    <li>
      <a routerLink="/dokumenty" routerLinkActive="active">{{
        "CORE.MENU.certifications" | translate
      }}</a>
    </li>
    <!-- <li><a routerLink="/zamowienie" routerLinkActive="active">{{'CORE.MENU.orders' | translate}}</a></li> -->
    <li>
      <a routerLink="/kontakt" routerLinkActive="active">{{
        "CORE.MENU.contact" | translate
      }}</a>
    </li>
  </ul>
</div>
