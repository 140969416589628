<a [href]="this.productUrl" target="_blank" class="product-icon {{this.color}}">
  <div class="product-icon__pic">
    <img src={{this.pictureUrl}} alt={{this.title}}>
  </div>
  <div class="product-icon__name">
    <h3 [innerHTML]="title"></h3>
    <i class="icon-angle-right"></i>
  </div>
  <span class="product-icon__top"></span>
</a>
