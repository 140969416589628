import { Component, ViewChild, AfterContentInit,  AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { IPropsData } from '../../shared/props/prop-element/prop-element.model';
import { ActivatedRoute, Params } from '@angular/router';
import { ScrollToElementService } from '../../core/services/scroll-to-element.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { SetDocumentLinkPipe } from '../../core/pipes/set-document-link.pipe';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, AfterContentInit {
  @ViewChild('certyfikaty', {static: true}) certSection: ElementRef;
  shouldScrollToCert: boolean;
  currentLang = 'pl';
  propsList: Array<IPropsData> = [
    {
      iconSrc: 'assets/img/value-idea.svg',
      iconAlt: 'CORE.VALUES.value-idea-title',
      title: 'CORE.VALUES.value-idea-title',
      description: 'CORE.VALUES.value-idea'
    },
    {
      iconSrc: 'assets/img/value-shipping.svg',
      iconAlt: 'CORE.VALUES.value-shipping-title',
      title: 'CORE.VALUES.value-shipping-title',
      description: 'CORE.VALUES.value-shipping'
    },
    {
      iconSrc: 'assets/img/value-certification.svg',
      iconAlt: 'CORE.VALUES.value-certification-title',
      title: 'CORE.VALUES.value-certification-title',
      description: 'CORE.VALUES.value-certification',
      link: true,
      linkContent: 'CORE.VALUES.more',
      scrollMethod: true
    },
    {
      iconSrc: 'assets/img/value-nato.svg',
      iconAlt: 'CORE.VALUES.value-nato-title',
      title: 'CORE.VALUES.value-nato-title',
      description: 'CORE.VALUES.value-nato',
      link: true,
      linkContent: 'CORE.VALUES.more',
      linkHref: this.setDocumentLinkPipe.transform('ncage', this.currentLang),
      scrollMethod: false,
      isTargetBlank: true,
    }
  ];

  constructor(
    private scrollService: ScrollToElementService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private setDocumentLinkPipe: SetDocumentLinkPipe,
  ) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.changeLanguage();
    });
  }

  scrollToCertSection(event?: any) {
    let delay: number;
    delay = event ? 0 : 300;

    setTimeout(() => {
      this.scrollService.scroll(this.certSection.nativeElement);
    }, delay);
  }

  ngOnInit() {
    this.shouldScrollToCert = this.route.snapshot.queryParams.scroll === 'certyfikaty';
    this.changeLanguage();
  }

  ngAfterContentInit() {
    if (this.shouldScrollToCert) {
      this.scrollToCertSection();
    }
  }

  changeLanguage() {
    this.currentLang = this.translate.currentLang;
  }
}
