import { NgModule } from '@angular/core';
import { MainComponent } from './main.component';
import { AboutComponent } from './about/about.component';
import { CertificationsComponent } from './certifications/certifications.component';
import { OrderComponent } from './order/order.component';
import { ContactComponent } from './contact/contact.component';
import { TranslateModule } from '@ngx-translate/core';
import { TemporaryComponent } from './temporary/temporary.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NewsListComponent } from './about/news-list/news-list.component';
import { NewsDetailComponent } from './news-detail/news-detail.component';
import { AgmCoreModule } from '@agm/core';
import { SharedModule } from '../shared/shared.module';
import { OrderFormComponent } from './order/order-form/order-form.component';
import { ProductFormComponent } from './order/order-form/product-form/product-form.component';
import { ComplaintFormComponent } from './complaint-form/complaint-form.component';
import { LayoutModule } from './layout/layout.module';
import { HomeModule } from './home/home.module';
import { ProductsModule } from './products/products.module';
import { ContactBoxComponent } from './contact/contact-box/contact-box.component';
import { ContactLinkComponent } from './contact/contact-link/contact-link.component';
import { CookieLawModule } from 'angular2-cookie-law';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieLawComponent } from './cookie-law/cookie-law.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { CoreModule } from '../core/core.module';
import { SetDocumentLinkPipe } from '../core/pipes/set-document-link.pipe';
import { SafeSiapComponent } from './safe-siap/safe-siap.component';
@NgModule({
  imports: [
    NgxUsefulSwiperModule,
    CoreModule,
    SharedModule,
    TranslateModule,
    LayoutModule,
    HomeModule,
    ProductsModule,
    BrowserAnimationsModule,
    CookieLawModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDxjPPuIeSWcW8KHbPZ60dwnC-FaXuhugg',
      libraries: ['places']
    }),
  ],
  declarations: [
    MainComponent,
    AboutComponent,
    CertificationsComponent,
    OrderComponent,
    ContactComponent,
    TemporaryComponent,
    NotFoundComponent,
    NewsListComponent,
    NewsDetailComponent,
    OrderFormComponent,
    ProductFormComponent,
    ComplaintFormComponent,
    ContactBoxComponent,
    ContactLinkComponent,
    CookieLawComponent,
    SafeSiapComponent
  ],
  providers: [
    SetDocumentLinkPipe,
  ]
})
export class MainModule {
}
