<div class="content-wrapper_margin">
  <div class="news-detail">
    <img src="./assets/img/banner_news.jpg" alt="nazwa" class="news-detail__banner">

    <div class="container">
      <div class="news-detail__title main-title" [routerLink]="['/news/2']">
        <strong>{{news.title}}</strong>
      </div>
      <div class="news-detail__date">{{news.date}}</div>
      <div class="news-detail__content">
        <p>{{news.content}}</p>
      </div>
    </div>
  </div>
</div>
