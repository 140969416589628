import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ControlMessagesComponent } from './control-messages/control-messages.component';
import { SpinnerComponent} from './spinner/spinner.component';
import { PropsComponent } from './props/props.component';
import { PropsElementComponent } from './props/prop-element/prop-element.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { NewsComponent } from './news/news.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NgbModule,
    RouterModule
  ],
  declarations: [
    ControlMessagesComponent,
    SpinnerComponent,
    PropsComponent,
    PropsElementComponent,
    NewsComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    NgbModule,
    RouterModule,
    ControlMessagesComponent,
    SpinnerComponent,
    PropsComponent,
    PropsElementComponent,
    NewsComponent
  ]
})
export class SharedModule { }
