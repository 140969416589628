<div [formGroup]="productForm" #newProduct id='product-{{productId}}'>
  <div class="form-group row">
    <label for="carType" class="col-md-4 col-form-label">
      {{'ORDER.carType' | translate}}
    </label>
    <div class="col-md-8">
      <input id="carType" class="form-control" formControlName="typeName">
      <control-messages [control]="productForm.controls['typeName']"></control-messages>
    </div>
  </div>
  <div class="form-group row">
    <label for="voltage" class="col-md-4 col-form-label">
      {{'ORDER.voltage' | translate}}
    </label>
    <div class="col-md-8">
      <input id="voltage" class="form-control" placeholder="{{'ORDER.unitV' | translate}}" formControlName="voltage">
      <control-messages [control]="productForm.controls['voltage']"></control-messages>
    </div>
  </div>
  <div class="form-group row">
    <label for="capacity" class="col-md-4 col-form-label">
      {{'ORDER.capacity' | translate}}
    </label>
    <div class="col-md-8">
      <input id="capacity" class="form-control" placeholder="{{'ORDER.unitAh' | translate}}" formControlName="capacity">
      <control-messages [control]="productForm.controls['capacity']"></control-messages>
    </div>
  </div>
  <div class="form-group row">
    <label for="outerDimension" class="col-md-4 col-form-label">
      {{'ORDER.outerDimension' | translate}}
    </label>
    <div class="col-md-8">
      <input id="outerDimension" class="form-control" placeholder="{{'ORDER.unitDimensions' | translate}}" formControlName="outerDimension">
      <control-messages [control]="productForm.controls['outerDimension']"></control-messages>
    </div>
  </div>
  <div class="form-group row">
    <label for="innerDimension" class="col-md-4 col-form-label">
      {{'ORDER.innerDimension' | translate}}
    </label>
    <div class="col-md-8">
      <input id="innerDimension" class="form-control" placeholder="{{'ORDER.unitDimensions' | translate}}" formControlName="innerDimension">
      <control-messages [control]="productForm.controls['innerDimension']"></control-messages>
    </div>
  </div>
  <div class="form-group row">
    <label class="col-md-4 col-form-label">{{'ORDER.connectionWay' | translate}}</label>
    <div class="col-md-8">
      <div class="form-check form-check-inline">
        <label class="form-check-label">
        <span class="simple-radio">
          <input type="radio" class="form-check-input simple-radio__radio" value="welded" formControlName="connectionWay">
          <span class="simple-radio__cover">
            <i class="icon-circle-empty"></i>
            <i class="icon-dot-circled"></i>
          </span>
        </span>
          {{'ORDER.welded' | translate}}
          <control-messages [control]="productForm.controls['connectionWay']"></control-messages>
        </label>
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label">
        <span class="simple-radio">
          <input type="radio" class="form-check-input simple-radio__radio" value="twisted" formControlName="connectionWay">
          <span class="simple-radio__cover">
            <i class="icon-circle-empty"></i>
            <i class="icon-dot-circled"></i>
          </span>
        </span>
          {{'ORDER.twisted' | translate}}
        </label>
      </div>
    </div>
  </div>

  <div class="form-group row">
    <label class="col-md-4 col-form-label">{{'ORDER.aquaSystem' | translate}}</label>
    <div class="col-md-8">
      <div class="form-check form-check-inline">
        <label class="form-check-label">
        <span class="simple-radio">
          <input type="radio" class="form-check-input simple-radio__radio" value="off" formControlName="aquaSystem">
          <span class="simple-radio__cover">
            <i class="icon-circle-empty"></i>
            <i class="icon-dot-circled"></i>
          </span>
        </span>
          {{'ORDER.yes' | translate}}
        </label>
      </div>
      <div class="form-check form-check-inline">
        <label class="form-check-label">
        <span class="simple-radio">
          <input type="radio" class="form-check-input simple-radio__radio" value="on" formControlName="aquaSystem">
          <span class="simple-radio__cover">
            <i class="icon-circle-empty"></i>
            <i class="icon-dot-circled"></i>
          </span>
        </span>
          {{'ORDER.no' | translate}}
        </label>
      </div>
    </div>

    <control-messages [control]="productForm.controls['aquaSystem']"></control-messages>
  </div>
  <div class="form-group row">
    <label for="orderQuantity" class="col-md-4 col-form-label">
      {{'ORDER.orderQuantity' | translate}}
    </label>
    <div class="col-md-8">
      <input id="orderQuantity" class="form-control" formControlName="orderQuantity">
      <control-messages [control]="productForm.controls['orderQuantity']"></control-messages>
    </div>
  </div>
</div>
