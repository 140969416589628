import { RouterModule, Routes } from "@angular/router";
import { MainComponent } from "./main/main.component";
import { HomeComponent } from "./main/home/home.component";
import { AboutComponent } from "./main/about/about.component";
import { ProductsTypeComponent } from "./main/products/products-type/products-type.component";
import { CertificationsComponent } from "./main/certifications/certifications.component";
import { OrderComponent } from "./main/order/order.component";
import { ContactComponent } from "./main/contact/contact.component";
import { NewsDetailComponent } from "./main/news-detail/news-detail.component";
import { ComplaintFormComponent } from "./main/complaint-form/complaint-form.component";
import { NotFoundComponent } from "./main/not-found/not-found.component";
import { SafeSiapComponent } from "./main/safe-siap/safe-siap.component";

const routing: Routes = [
  {
    path: "",
    component: MainComponent,
    children: [
      {
        path: "",
        component: HomeComponent,
        pathMatch: "full",
      },
      {
        path: "o-firmie",
        component: AboutComponent,
      },
      {
        path: "produkty",
        component: ProductsTypeComponent,
      },
      {
        path: "dokumenty",
        component: CertificationsComponent,
      },
      // {
      //   path: 'zamowienie',
      //   component: OrderComponent
      // },
      {
        path: "kontakt",
        component: ContactComponent,
      },
      {
        path: "news/:id",
        component: NewsDetailComponent,
      },
      {
        path: "reklamacja",
        component: ComplaintFormComponent,
      },
      {
        path: "bezpieczny-siap",
        component: SafeSiapComponent,
      },
      {
        path: "**",
        component: NotFoundComponent,
      },
    ],
  },
];

export const appRouting = RouterModule.forRoot(routing);
