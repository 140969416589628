<div class="contact-box">
  <div class="contact-box__title">
    <i [class]="boxData.titleIconClass"></i>
    <span [innerHTML]="boxData.title | translate"></span>
  </div>
  <div class="contact-box__info">
    <div class="contact-box__person" *ngFor="let row of boxData.rows">
      <span class="contact-box__row contact-box__preson-name">
        <strong>{{ row.person }}</strong>
        <span *ngIf="row.description">
          -
          <span [innerHTML]="row.description | translate"> </span>
        </span>
      </span>
      <span class="contact-box__row" *ngFor="let infoRow of row.infoRows">
        <ng-continer *ngIf="infoRow.type === 'space'; else default">
          <br />
        </ng-continer>

        <ng-template #default>
          <label>{{ infoRow.label | translate }}</label>
          <app-contact-link [linkData]="infoRow"></app-contact-link>
        </ng-template>
      </span>
    </div>
  </div>
</div>
