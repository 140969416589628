<div class="content-wrapper_margin">
  <div class="container">
    <h1 class="page-title">Bezpieczny S.I.A.P.</h1>

    <p>
      „S.I.A.P.” Sp. z o.o. realizuje projekt „Bezpieczny S.I.A.P.”
      dofinansowany z Funduszy Europejskich w ramach Regionalnego Programu
      Operacyjnego Województwa Śląskiego na lata 2014-2020 dla osi priorytetowej
      VIII Regionalne kadry gospodarki opartej na wiedzy dla Działania 8.3
      Poprawa dostępu do profilaktyki, diagnostyki i rehabilitacji leczniczej
      ułatwiającej pozostanie w zatrudnieniu i powrót do pracy dla Poddziałania
      8.3.2 Realizowanie aktywizacji zawodowej poprzez zapewnienie właściwej
      opieki zdrowotnej.
    </p>
    <p>
      <strong
        >Projekt będzie realizowany w okresie od 2023-09-01 do
        2023-30-30</strong
      ><br />
      <strong>Wartość całkowita projektu: 473 349,55 zł</strong><br />
      <strong>Koszty kwalifikowane: 473 349,55 zł</strong><br />
      <strong>Dofinansowanie UE: 402 347,11 zł</strong><br />
    </p>
    <p>
      Celem głównym projektu &quot;Bezpieczny S.I.A.P.&quot; jest eliminacja do
      30.11.2023 r. zdrowotnych czynników ryzyka związanych głównie z
      obciążeniem układu mięśniowo-szkieletowego u 65 (K=5) pracowników
      zatrudnionych na umowę o pracę na co najmniej 1⁄2 etatu w S.I.A.P.
    </p>
    <p>
      Cel główny projektu zostanie osiągnięty poprzez m.in. następujące
      działania:
    </p>

    <ul>
      <li>Komponent edukacyjno-informacyjny (działania świadomościowe)</li>
      <li>
        Zmodernizowanie stanowisk pracy w celu zmniejszenia zdrowotnych
        czynników ryzyka, aby umożliwić kontynuowanie pracy.
      </li>
      <li>
        Działania doradcze kadry zarządzającej/pracowników zarządzających
        zasobami ludzkimi u danego pracodawcy
      </li>
      <li>
        Działania związane z tworzeniem przyjaznego zdrowiu środowiska pracy
        (work-life balance)
      </li>
    </ul>
  </div>
</div>
