import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { SharedModule } from '../../shared/shared.module';
import { HeaderComponent } from './header/header.component';
import { NavComponent } from './header/nav/nav.component';
import { LanguagesComponent } from './header/languages/languages.component';



@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    FooterComponent,
    HeaderComponent,
    NavComponent,
    LanguagesComponent
  ],
  exports: [
    FooterComponent,
    HeaderComponent
  ]
})
export class LayoutModule { }
