import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { ProductsComponent } from './products.component';
import { ProductsTypeComponent } from './products-type/products-type.component';
import { ProductTypeDescriptionComponent } from './products-type/product-type-description/product-type-description.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    ProductsComponent,
    ProductsTypeComponent,
    ProductTypeDescriptionComponent
  ],
  exports: [
    ProductsComponent,
    ProductsTypeComponent
  ]
})
export class ProductsModule { }
