import { Component, OnInit } from '@angular/core';
import {NewsService, iNewsData} from '../../news.service';
import { IPropsData } from '../../shared/props/prop-element/prop-element.model';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { SetDocumentLinkPipe } from '../../core/pipes/set-document-link.pipe';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  currentLang = 'pl';
  propsList: Array<IPropsData> = [
    {
      iconSrc: 'assets/img/value-idea.svg',
      iconAlt: 'CORE.VALUES.value-idea-title',
      title: 'CORE.VALUES.value-idea-title',
      description: 'CORE.VALUES.value-idea'
    },
    {
      iconSrc: 'assets/img/value-shipping.svg',
      iconAlt: 'CORE.VALUES.value-shipping-title',
      title: 'CORE.VALUES.value-shipping-title',
      description: 'CORE.VALUES.value-shipping'
    },
    {
      iconSrc: 'assets/img/value-certification.svg',
      iconAlt: 'CORE.VALUES.value-certification-title',
      title: 'CORE.VALUES.value-certification-title',
      description: 'CORE.VALUES.value-certification',
      link: true,
      linkHref: '/o-firmie?scroll=certyfikaty',
      linkContent: 'CORE.VALUES.more',
    },
    {
      iconSrc: 'assets/img/value-nato.svg',
      iconAlt: 'CORE.VALUES.value-nato-title',
      title: 'CORE.VALUES.value-nato-title',
      description: 'CORE.VALUES.value-nato',
      link: true,
      linkContent: 'CORE.VALUES.more',
      linkHref: this.setDocumentLinkPipe.transform('ncage', this.currentLang),
      scrollMethod: false,
      isTargetBlank: true,
    }
  ];
  newsPromoted: iNewsData[] = [];

  constructor(
    private newsService: NewsService,
    private translate: TranslateService,
    private setDocumentLinkPipe: SetDocumentLinkPipe,
  ) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setLanguage();
    });
  }

  ngOnInit() {
    this.setLanguage();
  }

  setLanguage() {
    this.currentLang = this.translate.currentLang;
    this.newsPromoted = this.newsService.getNewsPromoted(this.currentLang);
  }
}
