import { Component, OnInit, Input } from '@angular/core';
import { IContactBoxData } from './contact-box.model';
import { contactList } from '../contact-list';

@Component({
  selector: 'app-contact-box',
  templateUrl: './contact-box.component.html',
  styleUrls: ['./contact-box.component.scss']
})
export class ContactBoxComponent implements OnInit {
  @Input() boxData: IContactBoxData;

  constructor() { }

  ngOnInit() {
  }

}
