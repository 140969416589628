<form [formGroup]="orderForm" (submit)="submitOrder()" class="validated-form" #form>
  <div class="form-group">
    <div class="form-check form-check-inline">
      <label class="form-check-label">
        <span class="simple-radio">
          <input type="radio" value="0" class="form-check-input simple-radio__radio" formControlName="orderType">
          <span class="simple-radio__cover">
            <i class="icon-circle-empty"></i>
            <i class="icon-dot-circled"></i>
          </span>
        </span>
        {{'ORDER.order' | translate}}
      </label>
    </div>
    <div class="form-check form-check-inline">
      <label class="form-check-label">
        <span class="simple-radio">
          <input type="radio" value="1" class="form-check-input simple-radio__radio" formControlName="orderType">
          <span class="simple-radio__cover">
            <i class="icon-circle-empty"></i>
            <i class="icon-dot-circled"></i>
          </span>
        </span>
        {{'ORDER.question' | translate}}
      </label>
    </div>
    <control-messages [control]="orderForm.get('orderType')"></control-messages>
  </div>
  <div class="row row_120">
    <div class="col-md-6">
      <div formArrayName="products">
        <h3 class="page-subtitle">{{'ORDER.productData' | translate}}</h3>

        <div class="product-form" *ngFor="let product of formProducts.controls; let i=index">
          <app-product-form [productForm]="formProducts.controls[i]" [productId]="i"></app-product-form>

          <div class="form__buttons">
            <div class="panel">
              <button type="button" class="form__button form__button__remove"
                      *ngIf="formProducts.controls.length > 1"
                      (click)="removeProduct(i)">
                <i class="icon-cancel-squared"></i>
                {{'ORDER.remove' | translate}}
              </button>
            </div>

            <div>
              <button type="button" class="form__button form__button__add" *ngIf="formProducts.length < 3"
                      (click)="addProduct()">
                <i class="icon-plus-squared-1"></i>
                {{'ORDER.add' | translate}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-6" formGroupName="client">
      <h3 class="page-subtitle">{{'ORDER.client-data' | translate}}</h3>

      <div class="form-group row">
        <label for="fullName" class="col-md-4 col-form-label">
          {{'ORDER.fullName' | translate}}
        </label>
        <div class="col-md-8">
          <input id="fullName" class="form-control" formControlName="fullName">
          <control-messages [control]="orderForm.get('client.fullName')"></control-messages>
        </div>
      </div>
      <div class="form-group row">
        <label for="corporation" class="col-md-4 col-form-label">
          {{'ORDER.corporation' | translate}}
        </label>
        <div class="col-md-8">
          <input id="corporation" class="form-control" formControlName="company">
          <control-messages [control]="orderForm.get('client.company')"></control-messages>
        </div>
      </div>

      <div class="form-group row">
        <label for="corporation" class="col-md-4 col-form-label">
          {{'ORDER.nip' | translate}}
        </label>
        <div class="col-md-8">
          <input id="nip" class="form-control" formControlName="nip">
          <control-messages [control]="orderForm.get('client.nip')"></control-messages>
        </div>
      </div>

      <div class="form-group row">
        <label for="email" class="col-md-4 col-form-label">
          {{'ORDER.email' | translate}}
        </label>
        <div class="col-md-8">
          <input id="email" class="form-control" formControlName="email">
          <small class="form__note">{{'ORDER.emailCopy' | translate}}</small>
          <control-messages [control]="orderForm.get('client.email')"></control-messages>
        </div>
      </div>
      <div class="form-group row">
        <label for="phone" class="col-md-4 col-form-label">
          {{'ORDER.phone' | translate}}
        </label>
        <div class="col-md-8">
          <input id="phone" class="form-control" formControlName="phoneNumber">
          <control-messages [control]="orderForm.get('client.phoneNumber')"></control-messages>
        </div>
      </div>
      <div class="form-group row">
        <label class="col-md-4 col-form-label">
          {{'ORDER.preferContact' | translate}}
        </label>
        <div class="col-md-8">
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <span class="simple-radio">
                <input type="radio" value="email" class="form-check-input simple-radio__radio"
                       formControlName="contactMethod">
                <span class="simple-radio__cover">
                  <i class="icon-circle-empty"></i>
                  <i class="icon-dot-circled"></i>
                </span>
              </span>
              {{'ORDER.email' | translate}}
            </label>
          </div>
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <span class="simple-radio">
                <input type="radio" value="phone" class="form-check-input simple-radio__radio"
                       formControlName="contactMethod">
                <span class="simple-radio__cover">
                  <i class="icon-circle-empty"></i>
                  <i class="icon-dot-circled"></i>
                </span>
              </span>
              {{'ORDER.phone' | translate}}
            </label>
          </div>
        </div>

        <control-messages [control]="orderForm.get('client.contactMethod')"></control-messages>
      </div>
    </div>
  </div>
  <div class="row">

    <div class="col-sm-12 text-sm-right">
      <button class="button form__submit" *ngIf="!isLoading">
        {{'ORDER.submit' | translate }}
      </button>
      <button class="button form__submit" *ngIf="isLoading">
        <app-spinner></app-spinner>
      </button>
    </div>

    <div class="col-sm-12 col-md-6 ml-md-auto" *ngIf="!isLoading && isSended">
      <p class="form__alert">
        <ngb-alert [type]="'success'" [dismissible]="false" >
          {{'ORDER.send_success' | translate}}
        </ngb-alert>
      </p>
    </div>
    <div class="col-sm-12 col-md-6 ml-md-auto" *ngIf="!isLoading && isError">
      <p class="form__alert">
        <ngb-alert [type]="'danger'" [dismissible]="false" >
          {{'ORDER.send_failure' | translate}}
        </ngb-alert>
      </p>
    </div>

  </div>
</form>
