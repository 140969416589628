import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { HomeComponent } from './home.component';
import { ProductIconComponent } from './products-icons-list/product-icon/product-icon.component';
import { ProductsIconsListComponent } from './products-icons-list/products-icons-list.component';
import { CoreModule } from '../../core/core.module';
import { SetDocumentLinkPipe } from '../../core/pipes/set-document-link.pipe';

@NgModule({
  imports: [
    SharedModule,
    CoreModule,
  ],
  declarations: [
    HomeComponent,
    ProductIconComponent,
    ProductsIconsListComponent
  ],
  exports: [
    HomeComponent
  ],
  providers: [
    SetDocumentLinkPipe,
  ]
})
export class HomeModule { }
