import { Component, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-certifications',
  templateUrl: './certifications.component.html',
  styleUrls: ['./certifications.component.scss']
})
export class CertificationsComponent implements OnInit {
  currentLang = 'pl';

  constructor(
    private translate: TranslateService,
  ) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setLanguage();
    });
  }

  ngOnInit() {
    this.setLanguage();
  }

  setLanguage() {
    this.currentLang = this.translate.currentLang;
  }

}
