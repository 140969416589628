import { Injectable } from '@angular/core';
import { NewsList } from './mock-news';
import { NewsListEn } from './mock-news-en';

@Injectable()
export class NewsService {

  getNews(lang: string, id?: number): iNewsData|iNewsData[] {
    let news: iNewsData[];
    lang === 'pl' ? news = NewsList : news = NewsListEn;
    if (id || id === 0) {
      return news.find((value) => {
        return value.id === id;
      });
    }
    return news;
  }

  getNewsPromoted(lang: string): iNewsData[] {
    let newsList: iNewsData[];
    lang === 'pl' ? newsList = NewsList : newsList = NewsListEn;
    function isPromoted(news) {
      return news.isPromoted;
    }

    const newsPromoted = newsList.filter(isPromoted);
    return newsPromoted;
  }
}

export interface iNewsData {
  id: number;
  title: string;
  content: string;
  date: string;
  isPromoted: boolean;
}

