import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IPropsData } from './prop-element/prop-element.model';

@Component({
  selector: 'app-props',
  templateUrl: './props.component.html',
  styleUrls: ['./props.component.scss']
})
export class PropsComponent {
  @Input() propsList: Array<IPropsData> = [];
  @Output() onChildScroll = new EventEmitter<boolean>();


  constructor() {}

  handleScroll(event: boolean) {
    this.onChildScroll.emit(event);
  }
}
