import { IContactBoxData } from "./contact-box/contact-box.model";

export const contactList: Array<IContactBoxData> = [
  {
    title: "CORE.CONTACT.management",
    titleIconClass: "icon-users",
    rows: [
      {
        person: "Carlo Pizzini",
        description: "CORE.CONTACT.mainManager",
        infoRows: [
          {
            label: "CORE.CONTACT.mail",
            content: "carlo.pizzini@siap.pl",
            type: "email",
          },
        ],
      },
    ],
  },
  {
    title: "CORE.CONTACT.department-office",
    titleIconClass: "icon-phone-squared",
    rows: [
      {
        person: "Ewa Grabarczyk",
        infoRows: [
          {
            label: "CORE.CONTACT.phone",
            content: "+48 (0) 32 238 27 88",
            type: "phone",
            linkContent: "+48(0)322382788",
          },
          {
            label: "CORE.CONTACT.mail",
            content: "biuro@siap.pl",
            type: "email",
          },
        ],
      },
    ],
  },
  {
    title: "CORE.CONTACT.department-accounts",
    titleIconClass: "icon-calc",
    rows: [
      {
        person: "Beata Raczyńska",
        description: "CORE.CONTACT.person-chief-accountant",
        infoRows: [
          {
            label: "CORE.CONTACT.mail",
            content: "beata.raczynska@siap.pl",
            type: "email",
          },
          {
            label: "CORE.CONTACT.phone",
            content: "+48 (0) 32 238 27 88",
            type: "phone",
            linkContent: "+48(0)322382788",
          },
        ],
      },
      {
        person: "Joanna Sokołowska",
        description: "CORE.CONTACT.person-vice-accountant",
        infoRows: [
          {
            label: "CORE.CONTACT.mail",
            content: "joanna.sokolowska@siap.pl",
            type: "email",
          },
          {
            label: "CORE.CONTACT.phone",
            content: "+48 (0) 32 238 27 88",
            ext: "24",
            type: "phone",
            linkContent: "+48(0)322382788",
          },
        ],
      },
      {
        person: "Beata Połowczuk",
        description: "CORE.CONTACT.person-accountant",
        infoRows: [
          {
            label: "CORE.CONTACT.mail",
            content: "beata.polowczuk@siap.pl",
            type: "email",
          },
          {
            label: "CORE.CONTACT.phone",
            content: "+48 (0) 32 238 27 88",
            ext: "23",
            type: "phone",
            linkContent: "+48(0)322382788",
          },
        ],
      },
    ],
  },
  {
    title: "CORE.CONTACT.department-technical",
    titleIconClass: "icon-cog-1",
    rows: [
      {
        person: "Kamil Szeląg",
        description: "CORE.CONTACT.vice-director-production",
        infoRows: [
          {
            label: "CORE.CONTACT.mail",
            content: "kamil.szelag@siap.pl",
            type: "email",
          },
          {
            label: "CORE.CONTACT.phone",
            content: "+48 601 931 731",
            type: "phone",
            linkContent: "+480601931731",
          },
        ],
      },
      {
        person: "Daniel Skrobowski",
        description: "CORE.CONTACT.person-quality",
        infoRows: [
          {
            label: "CORE.CONTACT.mail",
            content: "daniel.skrobowski@siap.pl",
            type: "email",
          },
          {
            label: "CORE.CONTACT.phone",
            content: "+48 (0) 32 238 27 88",
            ext: "35",
            type: "phone",
            linkContent: "+480322382788",
          },
        ],
      },
      {
        person: "Sławomir Cyganek",
        description: "CORE.CONTACT.deputy-technologist",
        infoRows: [
          {
            label: "CORE.CONTACT.mail",
            content: "slawomir.cyganek@siap.pl",
            type: "email",
          },
          {
            label: "CORE.CONTACT.phone",
            content: "+48 (0) 32 238 27 88",
            ext: "35",
            type: "phone",
            linkContent: "+480322382788",
          },
        ],
      },
    ],
  },
  {
    title: "CORE.CONTACT.department-sales",
    titleIconClass: "icon-handshake-o",
    rows: [
      {
        person: "Ewa Zaremba",
        description: "CORE.CONTACT.person-order",
        infoRows: [
          {
            label: "CORE.CONTACT.mail",
            content: "ewa.zaremba@siap.pl",
            type: "email",
          },
          {
            label: "CORE.CONTACT.phone",
            content: "+48 (0) 32 238 27 88",
            ext: "29",
            type: "phone",
            linkContent: "+480322382788",
          },
          {
            label: "CORE.CONTACT.phone",
            content: "+48 603 035 657",
            type: "phone",
            linkContent: "+48603035657",
          },
        ],
      },
      {
        person: "Sabina Zięba",
        description: "CORE.CONTACT.person-sales-foreign",
        infoRows: [
          {
            label: "CORE.CONTACT.mail",
            content: "sabina.zieba@siap.pl",
            type: "email",
          },
          {
            label: "CORE.CONTACT.phone",
            content: "+48 601 931 421",
            ext: "22",
            type: "phone",
            linkContent: "+48601931421",
          },
        ],
      },
    ],
  },
  {
    title: "CORE.CONTACT.department-production",
    titleIconClass: "icon-cog-alt",
    rows: [
      {
        person: "Paolo Poli",
        description: "CORE.CONTACT.person-production",
        infoRows: [
          {
            label: "CORE.CONTACT.mail",
            content: "paolo.poli@siap.pl",
            type: "email",
          },
          {
            label: "CORE.CONTACT.phone",
            content: "+48 (0) 32 238 27 88",
            type: "phone",
            linkContent: "+480322382788",
          },
        ],
      },
      {
        person: "Jarosław Łupieżowiec",
        description: "CORE.CONTACT.person-master-of-production",
        infoRows: [
          {
            label: "CORE.CONTACT.mail",
            content: "jaroslaw.lupiezowiec@siap.pl",
            type: "email",
          },
          {
            label: "CORE.CONTACT.phone",
            content: "+48 (0) 32 238 27 88",
            ext: "33",
            type: "phone",
            linkContent: "+48(0)322382788",
          },
          {
            label: "CORE.CONTACT.phone",
            content: "+48 601 931 701",
            type: "phone",
            linkContent: "+48601931701",
          },
        ],
      },
    ],
  },
  {
    title: "CORE.CONTACT.department-personnel",
    titleIconClass: "icon-address-book-o",
    rows: [
      {
        person: "Małgorzata Sromek",
        infoRows: [
          {
            label: "CORE.CONTACT.mail",
            content: "malgorzata.sromek@siap.pl",
            type: "email",
          },
          {
            label: "CORE.CONTACT.phone",
            content: "+48 (0) 32 238 27 88",
            ext: "21",
            type: "phone",
            linkContent: "+480322382788",
          },
        ],
      },
    ],
  },
  {
    title: "CORE.CONTACT.department-safety",
    titleIconClass: "icon-user-times",
    rows: [
      {
        person: "",
        description: "",
        infoRows: [
          {
            label: "CORE.CONTACT.mail",
            content: "iod@mbm.bielsko.pl",
            type: "email",
          },
          {
            label: "CORE.CONTACT.phone",
            content: "+48 515 724 845",
            type: "phone",
            linkContent: "+48515724845",
          },
          {
            type: "space",
          },
          {
            label: "CORE.CONTACT.mail",
            content: "sygnalista@siap.pl",
            type: "email",
          },
        ],
      },
    ],
  },
];
