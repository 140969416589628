<div class="product-icons__list">
  <h2 class="main-title">{{'CORE.PRODUCTS-ICONS.title' | translate}}</h2>
  <div class="row">
    <div class="col-xl-3 col-lg-6 col-md-6" *ngFor="let product of productsCategoryList">
      <app-product-icon 
        [title]="('CORE.PRODUCT-TYPE.name-' + product.color) | translate" 
        productUrl="{{product.productUrl}}" 
        color="{{product.color}}" 
        pictureUrl="{{product.pictureUrl}}" >
      </app-product-icon>
    </div>
  </div>
</div>
