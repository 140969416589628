import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';


@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: [ './nav.component.scss' ]
})
export class NavComponent implements OnInit {

  isMenuOpen = false;


  constructor(private router: Router) {
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationStart)) {
        return;
      }

      this.isMenuOpen = false;
    });
  }


  menuToggle() {
    this.isMenuOpen = !this.isMenuOpen;
  }

}
