import { Component, OnInit, Input } from '@angular/core';
import { IContactLinkData } from './contact-link.model';

@Component({
  selector: 'app-contact-link',
  templateUrl: './contact-link.component.html',
  styleUrls: ['./contact-link.component.scss']
})
export class ContactLinkComponent implements OnInit {
  @Input() linkData: IContactLinkData;
  linkHref: string;

  setLinkData = () => {
    switch (this.linkData.type) {
      case 'email':
        this.linkHref = 'mailto:' + (this.linkData.linkContent || this.linkData.content);
        break;
      case 'phone':
        this.linkHref = 'tel:' + (this.linkData.linkContent || this.linkData.content);
        break;
      default:
        this.linkHref = null;
    }
  }

  constructor() { }

  ngOnInit() {
    this.setLinkData();
  }

}
