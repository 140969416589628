import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from '../../core/services/validation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'control-messages',
  templateUrl: './control-messages.component.html',
  styleUrls: [ './control-messages.component.scss' ]
})
export class ControlMessagesComponent {

  @Input() control: FormControl;

  constructor(public translate: TranslateService) {
  }

  get errorMessage() {
    for (const propertyName in this.control.errors) {
      if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
        return ValidationService.getValidatorErrorMessage(propertyName, this.translate, this.control.errors[ propertyName ] );
      }
    }

    return null;
  }

}
