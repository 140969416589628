<div class="content-wrapper">
  <div class="about">
    <div class="about__banner"></div>

    <div class="container">
      <div class="about__desc">
        <h1 class="main-title">{{'CORE.MENU.about' | translate}}</h1>

        <p>{{'CORE.ABOUT.description-short' | translate}}</p>
        <p>{{'CORE.ABOUT.description' | translate}}</p>
      </div>

      <div class="about__values">
        <app-props [propsList]="propsList" (onChildScroll)="scrollToCertSection($event)"></app-props>

      </div>

      <div class="about__news">
        <app-news-list></app-news-list>
      </div>

      <h1 class="main-title" #certyfikaty>{{'CORE.ABOUT.certyfications' | translate}}</h1>
      <div class="certyfications">
        <a href="{{'iso9001' | setDocumentLink : currentLang}}" target="_blank" class="certyficate">
          <i class="icon-doc-text-inv"></i>
          <span [innerHTML]="'CORE.CERTIFICATIONS.certificate-iso9001' | translate"></span>
        </a>

        <a href="{{'iso14001' | setDocumentLink : currentLang}}" target="_blank" class="certyficate">
          <i class="icon-doc-text-inv"></i>
          <span [innerHTML]="'CORE.CERTIFICATIONS.certificate-iso14001' | translate"></span>
        </a>
      </div>
    </div>
  </div>
</div>

