import { Component, OnInit } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { IContactBoxData } from './contact-box/contact-box.model';
import { contactList } from './contact-list';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  public latitude: number;
  public longitude: number;
  public zoom: number;
  contactList: Array<IContactBoxData> = contactList;

  constructor(
    private mapsAPILoader: MapsAPILoader
  ) {}

  ngOnInit() {
    this.zoom = 15;
    this.latitude = 50.273295;
    this.longitude = 18.695825;
  }
}
