import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product-type-description',
  templateUrl: './product-type-description.component.html',
  styleUrls: ['./product-type-description.component.scss']
})
export class ProductTypeDescriptionComponent implements OnInit {

  @Input() typeColor: string;

  typeDescriptionsList = {
    blue: {
      description: 'CORE.PRODUCT-TYPE.blue-desc',
      use: 'CORE.PRODUCT-TYPE.blue-use',
      imgUrl1: './assets/img/products/1.jpg',
      listUrl: './assets/img/products/list-1.pdf'
    },
    pink: {
      description: 'CORE.PRODUCT-TYPE.pink-desc',
      use: 'CORE.PRODUCT-TYPE.pink-use',
      imgUrl1: './assets/img/products/3.jpg',
      listUrl: './assets/img/products/list-2.pdf'
    },
    yellow: {
      description: 'CORE.PRODUCT-TYPE.yellow-desc',
      use: 'CORE.PRODUCT-TYPE.yellow-use',
      imgUrl1: './assets/img/products/5.jpg',
      listUrl: './assets/img/products/list-3.pdf'
    },
    green: {
      description: 'CORE.PRODUCT-TYPE.green-desc',
      use: 'CORE.PRODUCT-TYPE.green-use',
      imgUrl1: './assets/img/products/7.jpg',
      imgUrl2: './assets/img/products/8.jpg',
      listUrl: './assets/img/products/list-4.pdf'
    }
  };

  constructor(
  ) {}

  ngOnInit() {
  }

}
