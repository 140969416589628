import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient  } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class OrderService {

  constructor(
    private http: HttpClient
  ) {
  }

  sendOrder(orderForm): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/orders/new`,
      orderForm
    )
  }

}
