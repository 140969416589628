import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsService } from '../../news.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: [ './news-detail.component.scss' ]
})
export class NewsDetailComponent implements OnInit {

  newsId: number;
  news: any;
  lang = 'pl';

  constructor(
    private route: ActivatedRoute,
    public newsService: NewsService,
    private translate: TranslateService,
  ) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getNews();
    });
  }

  ngOnInit() {
    this.newsId = this.route.snapshot.params.id;
    this.getNews();
  }

  getNews() {
    this.lang = this.translate.currentLang;
    this.news = this.newsService.getNews(this.lang, +this.newsId);
  }

}
