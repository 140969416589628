<div class="content-wrapper_margin">
  <div class="certification">
    <div class="container">
      <h1 class="page-title">{{'CORE.MENU.certifications' | translate}}</h1>

      <div class="certification__list">
        <div class="certification__category">
          <div class="page-subtitle certification__subtitle" [innerHTML]="'CORE.CERTIFICATIONS.category-business' | translate"></div>

          <div class="row">
            <div class="col-lg-4 col-md-6">
              <a href="{{'siap' | setDocumentLink : currentLang}}" target="_blank" class="certificate">
                <span class="certificate__icon"><i class="icon-doc-text-inv"></i></span>
                <span class="certificate__title" [innerHTML]="'CORE.CERTIFICATIONS.certificate-index' | translate"></span>
              </a>
            </div>

            <div class="col-lg-4 col-md-6">
              <a routerLink="/reklamacja" target="_blank" class="certificate">
                <span class="certificate__icon"><i class="icon-doc-text-inv"></i></span>
                <span class="certificate__title" [innerHTML]="'CORE.CERTIFICATIONS.certificate-complaint' | translate"></span>
              </a>
            </div>

            <div class="col-lg-4 col-md-6">
              <a href="{{'ncage' | setDocumentLink : currentLang}}" target="_blank" class="certificate">
                <span class="certificate__icon"><i class="icon-doc-text-inv"></i></span>
                <span class="certificate__title" [innerHTML]="'CORE.CERTIFICATIONS.certificate-ncage' | translate"></span>
              </a>
            </div>
          </div>
        </div>

        <div class="certification__category">
          <div class="page-subtitle certification__subtitle" [innerHTML]="'CORE.CERTIFICATIONS.category-adr' | translate"></div>

          <div class="row">
            <div class="col-lg-4 col-md-6">
              <a href="#" target="_blank" class="certificate">
                <span class="certificate__icon"><i class="icon-doc-text-inv"></i></span>
                <span class="certificate__title" [innerHTML]="'CORE.CERTIFICATIONS.certificate-adr-trasport' | translate"></span>
              </a>
            </div>

            <div class="col-lg-4 col-md-6">
              <a href="#" target="_blank" class="certificate">
                <span class="certificate__icon"><i class="icon-doc-text-inv"></i></span>
                <span class="certificate__title" [innerHTML]="'CORE.CERTIFICATIONS.certificate-owsid' | translate"></span>
              </a>
            </div>
            
            <div class="col-lg-4 col-md-6">
              <a href="{{'packaging' | setDocumentLink : currentLang}}" target="_blank" class="certificate">
                <span class="certificate__icon"><i class="icon-doc-text-inv"></i></span>
                <span class="certificate__title" [innerHTML]="'CORE.CERTIFICATIONS.packaging' | translate"></span>
              </a>
            </div>
          </div>
        </div>

        <div class="certification__category">
          <div class="page-subtitle certification__subtitle" [innerHTML]="'CORE.CERTIFICATIONS.category-iso' | translate"></div>

          <div class="row">
            <div class="col-lg-4 col-md-6">
              <a href="{{'declarationEnv' | setDocumentLink : currentLang}}" target="_blank" class="certificate">
                <span class="certificate__icon"><i class="icon-doc-text-inv"></i></span>
                <span class="certificate__title" [innerHTML]="'CORE.CERTIFICATIONS.certificate-quality' | translate"></span>
              </a>
            </div>

            <div class="col-lg-4 col-md-6">
              <a href="{{'iso9001' | setDocumentLink : currentLang}}" target="_blank" class="certificate">
                <span class="certificate__icon"><i class="icon-doc-text-inv"></i></span>
                <span class="certificate__title" [innerHTML]="'CORE.CERTIFICATIONS.certificate-iso9001' | translate"></span>
              </a>
            </div>

            <div class="col-lg-4 col-md-6">
              <a href="{{'iso14001' | setDocumentLink : currentLang}}" target="_blank" class="certificate">
                <span class="certificate__icon"><i class="icon-doc-text-inv"></i></span>
                <span class="certificate__title" [innerHTML]="'CORE.CERTIFICATIONS.certificate-iso14001' | translate"></span>
              </a>
            </div>
          </div>
        </div>

        <div class="certification__category">
          <div class="page-subtitle certification__subtitle" [innerHTML]="'CORE.CERTIFICATIONS.category-envirnoment' | translate"></div>

          <div class="row">

            <div class="col-lg-4 col-md-6">
              <a href="{{'reach' | setDocumentLink : currentLang}}" target="_blank" class="certificate">
                <span class="certificate__icon"><i class="icon-doc-text-inv"></i></span>
                <span class="certificate__title" [innerHTML]="'CORE.CERTIFICATIONS.reach' | translate"></span>
              </a>
            </div>

            <div class="col-lg-4 col-md-6">
              <a href="{{'ecoLeaflet' | setDocumentLink : currentLang}}" target="_blank" class="certificate">
                <span class="certificate__icon"><i class="icon-doc-text-inv"></i></span>
                <span class="certificate__title" [innerHTML]="'CORE.CERTIFICATIONS.certificate-eko' | translate"></span>
              </a>
            </div>


          </div>
        </div>

        <div class="certification__category">
          <div class="page-subtitle certification__subtitle" [innerHTML]="'CORE.CERTIFICATIONS.category-policy' | translate"></div>

          <div class="row">
            <div class="col-lg-4 col-md-6">
              <a href="{{'security' | setDocumentLink : currentLang}}" target="_blank" class="certificate">
                <span class="certificate__icon"><i class="icon-doc-text-inv"></i></span>
                <span class="certificate__title" [innerHTML]="'CORE.CERTIFICATIONS.security-policy' | translate"></span>
              </a>
            </div>

            <div class="col-lg-4 col-md-6">
              <a href="{{'cookies' | setDocumentLink : currentLang}}" target="_blank" class="certificate">
                <span class="certificate__icon"><i class="icon-doc-text-inv"></i></span>
                <span class="certificate__title" [innerHTML]="'CORE.CERTIFICATIONS.cookies-policy' | translate"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
