import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: [ './main.component.scss' ]
})
export class MainComponent implements OnInit {

  constructor(private translate: TranslateService) {
    translate.addLangs([ 'pl', 'it' ]);
    translate.setDefaultLang('pl');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/pl|fr/) ? browserLang : 'pl');
  }

  ngOnInit() {
  }

}
