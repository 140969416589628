<div class="content-wrapper">
  <div class="home">
    <div class="home__banner">
      <div class="hidden_md">
        <img src="assets/img/banner_mp.jpg" alt="{{'CORE.HOME.banner' | translate}}">
      </div>

      <div class="hidden visible_md">
        <img src="assets/img/banner_mp-md.jpg" alt="{{'CORE.HOME.banner' | translate}}">
      </div>
    </div>

    <div class="container">
      <div class="home__values">
        <app-props [propsList]="propsList"></app-props>
      </div>

      <app-products-icons-list></app-products-icons-list>

      <div class="home__specials">
        <div class="row">
          <div class="col-lg-6">
            <div class="specials__news">
              <app-news [newsDate]="newsPromoted[0]"></app-news>

              <a routerLink="/o-firmie" class="button">
                {{'CORE.HOME.news' | translate}}
                <i class="icon-angle-right"></i>
              </a>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                <a href="{{'siap' | setDocumentLink : currentLang}}" target="_blank" class="special-button">
                  <img src="./assets/img/catalog.svg" alt="{{'CORE.HOME.catalog' | translate}}">
                  <h4>{{'CORE.HOME.catalog' | translate}}</h4>
                </a>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-6">
                <a routerLink="/zamowienie" class="special-button">
                  <img src="./assets/img/offer.svg" alt="{{'CORE.HOME.offer' | translate}}">
                  <h4>{{'CORE.HOME.offer' | translate}}</h4>
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
