<div class="value__item">
    <div class="value__icon">
      <img [src]="elementData.iconSrc" alt="{{elementData.iconAlt | translate}}">
    </div>

    <div class="value__desc">
      <h3>{{elementData.title | translate}}</h3>
      {{elementData.description | translate}}
      <a *ngIf="elementData.link && !elementData.isTargetBlank" 
        [routerLink]="elementData.linkHref" 
        (click)="emitScroll()">
        {{elementData.linkContent | translate}} <i class="icon-angle-right"></i>
      </a>
      <a *ngIf="elementData.link && elementData.isTargetBlank" 
        [href]="elementData.linkHref" 
        target="_blank">
        {{elementData.linkContent | translate}} <i class="icon-angle-right"></i>
      </a>
    </div>
</div>
