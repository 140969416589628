import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IPropsData } from './prop-element.model';
import { Router, Params } from '@angular/router';



@Component({
  selector: 'app-prop-element',
  templateUrl: './prop-element.component.html',
  styleUrls: ['./prop-element.component.scss']
})
export class PropsElementComponent {
  @Input() elementData: IPropsData;
  @Output() onScroll = new EventEmitter<boolean>();

  constructor(
    private router: Router
  ) {}

  emitScroll() {
    if (this.elementData.scrollMethod ) {
      this.onScroll.emit(true);
    } else {
      this.router.navigate(['/o-firmie'], {queryParams: {'scroll': 'certyfikaty'}} );
    }
  }
}
