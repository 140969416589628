<div class="header">
  <div class="container">
    <div class="header__ue">
      <a routerLink="/bezpieczny-siap">
        <img src="assets/img/ue.jpg" alt="{{ 'CORE.HEADER.ue' | translate }}"
      /></a>
    </div>

    <div class="header__logo">
      <a routerLink="/"
        ><img
          src="assets/img/logo.svg"
          alt="{{ 'CORE.HEADER.logo' | translate }}"
      /></a>
    </div>

    <div class="header__menu">
      <div class="header__right">
        <a routerLink="/bezpieczny-siap">
          <img src="assets/img/ue.jpg" alt="{{ 'CORE.HEADER.ue' | translate }}"
        /></a>
        <div class="header__languages">
          <app-languages></app-languages>
        </div>
      </div>

      <div class="header__nav">
        <app-nav></app-nav>
      </div>
    </div>
  </div>
</div>
