import { Component, OnInit } from '@angular/core';
import {NewsService, iNewsData} from '../../../news.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss']
})
export class NewsListComponent implements OnInit {

  newsList: iNewsData|iNewsData[] = [];
  lang = 'pl';
  config: any = {
    navigation: {
      nextEl: '.js-newsSliderNext',
      prevEl: '.js-newsSliderPrev',
    },
    pagination: {
      el: '.js-newsSliderPagination',
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + (index + 1) + '</span>';
      },
    },
    spaceBetween: 20,
    slidesPerView: 3,
    breakpoints: {
      1199: {
        slidesPerView: 3
      },
      767: {
        slidesPerView: 2
      },
      0: {
        slidesPerView: 1,
      },
    }
  };

  constructor(
    private newsService: NewsService,
    private translate: TranslateService,
  ) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.getNews();
    });
  }

  ngOnInit() {
    this.getNews();
  }

  getNews() {
    this.lang = this.translate.currentLang;
    this.newsList = this.newsService.getNews(this.lang);
  }

}
