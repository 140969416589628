import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products-type',
  templateUrl: './products-type.component.html',
  styleUrls: ['./products-type.component.scss']
})
export class ProductsTypeComponent implements OnInit {

  choosenProductType = 'blue';

  constructor() { }

  ngOnInit() {
  }

  changeProductType(typeColor) {
    this.choosenProductType = typeColor;
  }

}
