import { Injectable, ElementRef } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollToElementService {
  constructor() { }

  scroll(elem) {
    if (elem) {
      window.scroll({
        top: elem.offsetTop,
        behavior: 'smooth'
      });
    }
  }
}
