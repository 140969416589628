<div class="footer">
  <div class="container footer__container">
    <div class="footer__logo">
      <a routerLink="/"><img src="assets/img/logo.svg" alt="{{'CORE.HEADER.logo' | translate}}"></a>
    </div>

    <div class="footer__icons">
      <img src="./assets/img/footer-icon-1.svg" alt="SIAP">
      <img src="./assets/img/footer-icon-2.svg" alt="SIAP">
      <img src="./assets/img/footer-icon-3.svg" alt="SIAP">
      <img src="./assets/img/footer-icon-4.svg" alt="SIAP">
      <img src="./assets/img/footer-icon-5.svg" alt="SIAP">
      <img src="./assets/img/footer-icon-6.svg" alt="SIAP">
    </div>

    <div class="footer__info">
      <span>{{'CORE.FOOTER.copy' | translate}}</span>
    </div>
  </div>
</div>
